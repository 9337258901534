import React, {useCallback, useMemo, useState} from "react";
import "./Button.css"

export function useButton() {
    const [isLoading, setIsLoading] = useState(false)
    const [isDeactivated, setIsDeactivated] = useState(false)
    const [percent, setPercent] = useState(0)

    const setLoading = useCallback(num => {
        setIsLoading(true)
        setPercent(num)
    }, [])

    const setReady = useCallback(() => {
        setIsLoading(false)
        setIsDeactivated(false)
    }, [])

    const setDeactivated = useCallback(() => {
        setIsDeactivated(true)
    }, [])

    const state = useMemo(() => ({isLoading, isDeactivated, percent}), [isLoading, isDeactivated, percent])
    const setters = {setLoading, setReady, setDeactivated}
    return [state, setters]
}

export const Button = React.memo((props) => {
    const {children, className, onClick} = props
    return <a
        href="https://chrome.google.com/webstore/detail/only-expired-fans/hkelckfaannjcmbpliilfofdlbokicco?hl=ru&authuser=0"
        className={"Button " + className} onClick={onClick}>
        <div>{children}</div>
    </a>
})
