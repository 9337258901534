import React, {useState} from "react";
import "./Spoiler.css"

export const Indicator = React.memo(({isOpen}) => {
    const background = isOpen ? "#FFFFFF" : "#000000"
    return <div className="SpoilerIndicator" style={{background}}/>
})

export const Spoiler = React.memo(({header, children, isOpen, onClick}) => {
    console.log("spoiler")
    let maxHeight, padding
    if (isOpen) {
        maxHeight = "100px"
        padding = undefined
    } else {
        maxHeight = "0px"
        padding = "0px"
    }
    return <div className="SpoilerOuter" onClick={onClick}>
        <div className="SpoilerHeader">{header}<Indicator isOpen={isOpen}/></div>
        <div className="SpoilerContent" style={{maxHeight, padding}}>{children}</div>
    </div>
})

const faq = [
    {
        header: "Is it safe for me?",
        children: "The bot runs on your pc, imitating a real person, making the bot invisible to onlyfans. We made this software for ourselves, so we made it 100% secure."
    },
    {
        header: "What if I have 2 accounts?",
        children: "Activation of the bot is associated with only 1 of your pages on onlyfans. Make sure you are logged into the correct onlyfans page during activation. If you haven't done so, please contact support."
    },
    {
        header: "Why it is really useful?",
        children: "The bot is designed to save time and increase earnings. By allowing expired subscribers to receive your messages, you generate potential revenue growth."
    }
]

export const SpoilerList = React.memo(() => {
    const [openedIndex, setOpenedIndex] = useState(-1)

    return <div>
        {faq.map(
            (question, index) => [
                (!!index && <div className="SpoilerLine"/>),
                <Spoiler {...question} onClick={() => setOpenedIndex(index)} isOpen={index === openedIndex}/>
            ]
        )}
    </div>
})