import React from "react";
import "./Header.css"

export const Header3 = React.memo(({top, left, children, ...props}) => {
    console.log("header")
    return <div className="Header3" style={{top, left, ...props}}>{children}</div>
})

export const Header2 = React.memo(({top, left, children, ...props}) => {
    console.log("header")
    return <div className="Header2" style={{top, left, ...props}}>{children}</div>
})

export const Header1 = React.memo(({children, ...props}) => {
    console.log("Header1")
    return <div className="Header1" style={props}>{children}</div>
})

export const Footer = React.memo(({children, ...props}) => {
    return <div className="Footer" style={props}>{children}</div>
})