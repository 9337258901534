import React from "react"
import "./Spots.css"

export const SpotPrimary = React.memo(() => {
    console.log("spotPrim")
    let style = {
        left: "259px",
        top: "-515px",
    }
    return <div className="SpotPrimary" style={style}/>
})

export const SpotSecondary = React.memo(() => {
    console.log("spotSec")
    let style = {
        left: "494px",
        top: "-181px"
    }
    return <div className="SpotSecondary" style={style}/>
})