import "./App.css"
import React from "react"
import {createStore, StoreProvider} from "easy-peasy"
import LandingPage from "./components/LandingPage"
import {BrowserView, MobileView} from "react-device-detect";
import MobilePage from "./components/MobilePage";


function insertStyleLink(link) {
    let linkElement = document.createElement("link")
    linkElement.rel = "stylesheet"
    linkElement.type = "text/css"
    linkElement.href = link
    document.body.append(linkElement)
}

function insertFont(link) {
    let linkElement = document.createElement("link")
    linkElement.rel = "stylesheet"
    linkElement.href = link
    document.body.append(linkElement)
}

insertStyleLink("/contentScript.css")
insertFont("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap")


const store = createStore({
    language: "EN"
})

const App = () => <StoreProvider store={store}>
    <BrowserView>
        <LandingPage/>
    </BrowserView>
    <MobileView>
        <MobilePage/>
    </MobileView>
</StoreProvider>

export default App
