import React from "react";
import "./Cell.css"

export const Cell = React.memo(({width, height, children, column, flexStart, spaceBetween, spaceAround, first, last, baseline, ...props}) => {
    console.log("Cell")
    let border = {}
    if (first) {
        border.borderLeft = 0
    }
    if (last) {
        border.borderBottom = 0
    }
    return <div className="Cell" style={{width, height, ...border, ...props}}>
        <FlexBox {...{flexStart, spaceBetween, spaceAround, baseline, column, children}}>
            {children}
        </FlexBox>
    </div>
})

export const FlexBox = React.memo(({flexStart, spaceBetween, spaceAround, baseline, column, children, ...props}) => {
    console.log("flexbox")
    let justifyContent
    if (flexStart) {
        justifyContent = "flex-start"
    }
    if (spaceBetween) {
        justifyContent = "space-between"
    }
    if (spaceAround) {
        justifyContent = "space-around"
    }

    let alignItems
    if (baseline) {
        alignItems = "baseline"
    }

    let flexDirection
    if (column) {
        flexDirection = "column"
    }
    return <div className="FlexBox" style={{
        flexDirection,
        justifyContent,
        alignItems,
        ...props
    }}>
        {children}
    </div>
})