import React from "react"
import {SpotPrimary, SpotSecondary} from "./Spot";
import {Cell, FlexBox} from "./Cell";
import {Footer, Header1, Header2, Header3} from "./Header";
import {Slogan} from "./Slogan";
import {Logo} from "./Logo";
import {Button} from "./Button";
import {Step} from "./Step";
import {Text} from "./Text";
import {Photo} from "./Photo";
import {SpoilerList} from "./Spoiler";
import "./Landing.css"
import "normalize.css"


export default () => {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    // const scrollbar_width = 19
    let scale = window.innerWidth / 1300
    let additionalHeight = window.outerHeight * scale
    const botFrameStyle = {
        position: "absolute",
        width: "1300px",
        transform: `scale(${scale})`,
        transformOrigin: "left top",
        // background: "#FFFFFF",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto auto auto",
        display: "grid",
        overflowX: "clip",
        fontFamily: "Raleway, sans-serif",
        background: "linear-gradient(180deg, #FFFFFF 82%, #8FE1FF 100%)"
    }

    return <div style={botFrameStyle}>
        <SpotSecondary/>
        <SpotPrimary/>
        <Cell column baseline first padding="0 25px">
            <Header1>MORE FANS<br/>
                GOT MASS MESSAGE -<br/>
                MORE MONEY YOU MAKE!</Header1>
            <Step opacity={.16} left="75px">It's secure. There is no need for a password or login.</Step>
            <Step opacity={.25} left="166px">Not detectable by OnlyFans.</Step>
            <Step opacity={.47} left="292px">You can automatically follow with just one click.</Step>
            <Step opacity={.69} left="408px">Up to ~3000 fans monthly.</Step>
            <Step opacity={1} left="532px">24/7 EN/RU support in telegram or by email.</Step>
            <FlexBox spaceAround margin="30px 0" height="fit-content">
                <Text>Start using Onlyfollower in one click</Text>
                <Button>ADD TO CHROME</Button>
            </FlexBox>
            <Photo/>
            <Header2>FREQUENTLY ASKED QUESTIONS</Header2>
        </Cell>
        <Cell column baseline first>
            <SpoilerList/>
            <Header3>If you have any questions, feel free to write to Online Support</Header3>
        </Cell>
        <Cell spaceAround first last>
            <Footer>
                From models with 🖤<br/>
                Support by email: <a href="mailto:adulttools@protonmail.com">adulttools@protonmail.com</a><br/>
                Support in telegram: <a href="https://t.me/Onlyfollower_Support">@Onlyfollower_Support</a><br/>
                2022
            </Footer>
        </Cell>
        <Cell last first height={additionalHeight}/>
    </div>
}